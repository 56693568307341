// Init Photoswipe.js
// es6
//
// uses:
// - https://github.com/dimsemenov/photoswipe
// - https://github.com/dimsemenov/photoswipe-dynamic-caption-plugin
// - https://photoswipe.com/getting-started/

const smallScreenPadding = {
  top: 0, bottom: 0, left: 0, right: 0
};
const largeScreenPadding = {
  top: 30, bottom: 30, left: 0, right: 0
};

const options = {
  gallery: 'body',
  children: '.photoswipe',
  paddingFn: (viewportSize) => {
    return viewportSize.x < 700 ? smallScreenPadding : largeScreenPadding
  },
  pswpModule: () => import(window.ProjectEnv.photoswipe.module),
};
let lightbox;
let caption;
let instance;

function getLightbox() {
  if (!lightbox) {
    lightbox = import(window.ProjectEnv.photoswipe.lightbox).then(
      (module) => module.default
    );
  }
  return lightbox;
}

function getDynamicCaption() {
  if (!caption) {
    caption = import(window.ProjectEnv.photoswipe.caption).then(
      (module) => module.default
    );
  }
  return caption;
}

function initPhotoswipe() {
  if (
    typeof window.ProjectEnv === 'undefined' ||
    window.ProjectEnv === null ||
    !window.ProjectEnv.photoswipe
  ) {
    return;
  }

  if (
    window.ProjectEnv.photoswipe.module &&
    window.ProjectEnv.photoswipe.lightbox &&
    document.querySelector(options.gallery + ' ' + options.children)
  ) {
    getLightbox()
      .then((lightbox) => {
        if (instance) {
          instance.destroy();
        }
        instance = new lightbox(options);
        return instance;
      })
      .then(addCaptions)
      .then((instance) => {
        instance.init();
      });
  }
}

function addCaptions(lightbox) {
  return getDynamicCaption()
    .then((dynamicCaption) => {
      new dynamicCaption(lightbox, {
        // Plugins options
        mobileLayoutBreakpoint: 700,
        type: 'below',
        horizontalEdgeThreshold: 20,
        mobileCaptionOverlapRatio: 1,
        captionContent: (slide) => {
          return slide.data.element.closest('div').querySelector('.pswp-caption-content').innerHTML;
        }
      });
    
      return lightbox;
    });
}

initPhotoswipe();
